import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import updateFill from '@iconify/icons-eva/save-fill';
import cancelFill from '@iconify/icons-eva/close-fill';
import { Link as RouterLink } from 'react-router-dom';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import PropTypes from 'prop-types';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';

// ----------------------------------------------------------------------

MiscChargesListMenu.propTypes = {
  item: PropTypes.object,
  onDeleteClick: PropTypes.func,
  onEditClick: PropTypes.func
};

export default function MiscChargesListMenu({
  item,
  mode,
  onDeleteClick,
  onEditClick,
  onUpdateClick,
  onCancelClick
}) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleDeleteClick = (item) => {
    setIsOpen(false);
    onDeleteClick && onDeleteClick(item);
  };

  const handleEditClick = (item) => {
    setIsOpen(false);
    onEditClick && onEditClick(item);
  };

  const handleUpdateClick = (item) => {
    setIsOpen(false);
    onUpdateClick && onUpdateClick(item);
  };

  const handleCancelClick = () => {
    setIsOpen(false);
    onCancelClick && onCancelClick();
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      {mode === 'EDIT' ? (
        <Menu
          open={isOpen}
          anchorEl={ref.current}
          onClose={() => setIsOpen(false)}
          PaperProps={{
            sx: { width: 200, maxWidth: '100%' }
          }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MenuItem sx={{ color: 'text.secondary' }} onClick={() => handleUpdateClick(item)}>
            <ListItemIcon>
              <Icon icon={updateFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Update" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>

          <MenuItem sx={{ color: 'text.secondary' }} onClick={() => handleCancelClick()}>
            <ListItemIcon>
              <Icon icon={cancelFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Cancel" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        </Menu>
      ) : (
        <Menu
          open={isOpen}
          anchorEl={ref.current}
          onClose={() => setIsOpen(false)}
          PaperProps={{
            sx: { width: 200, maxWidth: '100%' }
          }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MenuItem sx={{ color: 'text.secondary' }} onClick={() => handleDeleteClick(item)}>
            <ListItemIcon>
              <Icon icon={trash2Outline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>

          <MenuItem sx={{ color: 'text.secondary' }} onClick={() => handleEditClick(item)}>
            <ListItemIcon>
              <Icon icon={editFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        </Menu>
      )}
    </>
  );
}
