import http from '../../http-common';

class MiscellaneosInvoicingService {
  getMiscInvHeader() {
    const config = {
      headers: { 
        'Authorization': `Bearer ${global.userData.token}`,
      },
      responseType: 'application/json',
    };

    return http.get('/api/miscInvoicig/getMiscInvHeader', config);
  }

  getMiscInvDetails(miscInvHeaderId) {
    const config = {
      headers: { 
        'Authorization': `Bearer ${global.userData.token}`,
      },
      responseType: 'application/json',
    };

    return http.get(`/api/miscInvoicig/getMiscInvDetails/${miscInvHeaderId}`, config);
  }

  getCustomerForMiscInv() {
    const config = {
      headers: { 
        'Authorization': `Bearer ${global.userData.token}`,
      },
      responseType: 'application/json',
    };

    return http.get('/api/miscInvoicig/getCustomerForMiscInv', config);
  }

  insertMiscInvHeader(data) {
    const config = {
      headers: {
        Authorization: `Bearer ${global.userData.token}`
      },
      responseType: 'application/json'
    };
    return http.post('/api/miscInvoicig/insertMiscInvHeader', data, config);
  }

  insertMiscInvDetails(data) {
    const config = {
      headers: {
        Authorization: `Bearer ${global.userData.token}`
      },
      responseType: 'application/json'
    };
    return http.post('/api/miscInvoicig/insertMiscInvDetails', data, config);
  }

  deleteMiscInv(data) {
    const config = {
      headers: {
        Authorization: `Bearer ${global.userData.token}`
      },
      responseType: 'application/json'
    };
    return http.post('/api/miscInvoicig/deleteMiscInv', data, config);
  }

}

export default new MiscellaneosInvoicingService();
