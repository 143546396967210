import { Cookies } from 'react-cookie';
import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import starFill from '@iconify/icons-eva/star-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import uploadFill from '@iconify/icons-eva/upload-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import cubeFill from '@iconify/icons-eva/cube-fill';
import fileTextOutline from '@iconify/icons-eva/file-text-outline';
import questionMarkCircleFill from '@iconify/icons-eva/question-mark-circle-fill';

import peopleFill from '@iconify/icons-eva/people-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;
const cookies = new Cookies();

const sidebarConfig = () => {
  const userData = cookies.get('userData', { path: '/' });

  return [
    {
      title: 'Dashboard',
      path: '/dashboard',
      icon: getIcon(pieChart2Fill),
      isShown: true
    },
    {
      title: 'Inquiry',
      path: '/inquiry',
      icon: getIcon(questionMarkCircleFill),
      isShown: true
    },
    {
      title: 'Accounts Detail Uploader',
      path: '',
      icon: getIcon(uploadFill),
      isShown: true,
      children: [
        {
          title: 'Customer Uploader',
          path: '/accounts-detail-uploader/customer-uploader',
          icon: getIcon(uploadFill),
          isShown: true
        },
        {
          title: 'Brokerage Uploader',
          path: '/accounts-detail-uploader/brokerage-uploader',
          icon: getIcon(uploadFill),
          isShown: true
        },
        {
          title: 'SP Uploader',
          path: '/accounts-detail-uploader/sp-uploader',
          icon: getIcon(uploadFill),
          isShown: true
        },
        {
          title: 'OEX Uploader',
          path: '/accounts-detail-uploader/oex-uploader',
          icon: getIcon(uploadFill),
          isShown: true
        },
        {
          title: 'Payments Uploader',
          path: '/accounts-detail-uploader/payments-uploader',
          icon: getIcon(uploadFill),
          isShown: true
        },
        {
          title: 'MAWB Uploader',
          path: '/accounts-detail-uploader/mawb-uploader',
          icon: getIcon(uploadFill),
          isShown: true
        },
        {
          title: 'Details Uploader',
          path: '/accounts-detail-uploader/details-uploader',
          icon: getIcon(uploadFill),
          isShown: true
        },
        {
          title: 'Rate Card Uploader',
          path: '/accounts-detail-uploader/rate-cards-uploader',
          icon: getIcon(uploadFill),
          isShown: true
        },
        {
          title: 'BCC Uploader',
          path: '/accounts-detail-uploader/bcc-uploader',
          icon: getIcon(uploadFill),
          isShown: true
        },
        {
          title: 'Staff Uploader',
          path: '/accounts-detail-uploader/staff-uploader',
          icon: getIcon(uploadFill),
          isShown: true
        }
      ]
    },
    {
      title: 'Billing and Invoicing',
      path: '',
      icon: getIcon(fileTextFill),
      isShown: true,
      children: [
        {
          title: 'Generate Invoice',
          path: '/billing-and-invoicing/generate-invoice',
          icon: getIcon(fileTextFill),
          isShown: true
        },
        {
          title: 'SP Profitability Report',
          path: '/billing-and-invoicing/sp-profitability-report',
          icon: getIcon(fileTextFill),
          isShown: true
        },
        {
          title: 'Generate SOA',
          path: '/billing-and-invoicing/generate-soa',
          icon: getIcon(fileTextFill),
          isShown: true
        },
        {
          title: 'Miscellaneos Invoicing',
          path: '/billing-and-invoicing/miscellaneos-invoicing',
          icon: getIcon(fileTextFill),
          isShown: true
        }
        // {
        //   title: 'Domestic Attachment',
        //   path: '/billing-and-invoicing/domestic-attachment',
        //   icon: getIcon(fileTextFill),
        //   isShown: true
        // }
      ]
    },
    {
      title: 'File Maintenance',
      path: '',
      icon: getIcon(settings2Fill),
      isShown: true,
      children: [
        {
          title: 'Countries',
          path: '/file-maintenance/countries',
          icon: getIcon(settings2Fill),
          isShown: true
        },
        {
          title: 'IATA/Currency Rates',
          path: '/file-maintenance/iatacurrencyrates',
          icon: getIcon(settings2Fill),
          isShown: true
        },
        {
          title: 'Fuel Rates',
          path: '/file-maintenance/fuelcurrencyrates',
          icon: getIcon(settings2Fill),
          isShown: true
        },
        {
          title: 'ESC Rates',
          path: '/file-maintenance/esccurrencyrates',
          icon: getIcon(settings2Fill),
          isShown: true
        },
        {
          title: 'Desti Rates Blueprint',
          path: '/file-maintenance/desti-rates-blueprint',
          icon: getIcon(settings2Fill),
          isShown: true
        },
        {
          title: 'Customize Desti Rates Blueprint',
          path: '/file-maintenance/customize-desti-rates-blueprint',
          icon: getIcon(settings2Fill),
          isShown: true
        },
        {
          title: 'Costing Blueprint',
          path: '/file-maintenance/costing-blueprint',
          icon: getIcon(settings2Fill),
          isShown: true
        },
        {
          title: 'User',
          path: '/file-maintenance/user',
          icon: getIcon(peopleFill),
          isShown: userData && userData.userAccess === 1 ? true : false
        }
      ]
    }
  ];
};

export default sidebarConfig;
