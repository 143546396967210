import React, { useState, useEffect } from 'react';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  FormControlLabel,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  Alert,
  IconButton,
  Collapse,
  TableFooter,
  MenuItem
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// components
import Page from 'src/components/Page';
import Label from 'src/components/Label';
import Scrollbar from 'src/components/Scrollbar';
import SearchNotFound from 'src/components/SearchNotFound';
import MiscInvChargesListHead from './MiscInvChargesListHead';
import MiscInvChargesListToolbar from './MiscInvChargesListToolbar';
import MiscInvChargesListMoreMenu from './MiscInvChargesListMoreMenu';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'code', label: 'Code', alignRight: false },
  { id: 'vat', label: 'V', alignRight: false },
  { id: 'charges', label: 'Charges', alignRight: false },
  { id: 'desc', label: 'Explanation', alignRight: false },
  { id: 'amount', label: 'Amount', alignRight: true },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_miscellaneous) => _miscellaneous.code.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function MiscInvCharges({
  miscInvChargesList,
  onAddMiscInvChanges,
  onEditMiscInvChanges,
  onDeleteMiscInvChanges,
  disableProceed
}) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('id');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [mode, setMode] = useState('ADD');
  const [code, setCode] = useState('');
  const [vat, setVat] = useState('V');
  const [charges, setCharges] = useState('');
  const [desc, setDesc] = useState('');
  const [amount, setAmount] = useState('0.00');

  const [editCode, setEditCode] = useState('');
  const [codeEdit, setCodeEdit] = useState('');
  const [vatEdit, setVatEdit] = useState('V');
  const [chargesEdit, setChargesEdit] = useState('');
  const [descEdit, setDescEdit] = useState('');
  const [amountEdit, setAmountEdit] = useState('0.00');

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertType, setAlertType] = useState('info');
  const [alertMessage, setAlertMessage] = useState('');

  const vatOption = [
    {
      value: 'V',
      label: 'V'
    },
    {
      value: 'E',
      label: 'E'
    }
  ];

  const openAlert = (alertType, alertMessage) => {
    setAlertType(alertType);
    setAlertMessage(alertMessage);
    setAlertOpen(true);
  };

  const closeAlert = () => {
    setAlertType('info');
    setAlertMessage('');
    setAlertOpen(false);
  };

  useEffect(() => {
    if (alertOpen) {
      const timeId = setTimeout(() => {
        closeAlert();
      }, 5000);

      return () => {
        clearTimeout(timeId);
      };
    }
  }, [alertOpen]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = miscInvChargesList.map((n) => n.code);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - miscInvChargesList.length) : 0;

  const filteredMiscellaneous = applySortFilter(
    miscInvChargesList,
    getComparator(order, orderBy),
    filterName
  );

  // Add
  const miscInvSchemaAdd = Yup.object().shape({
    code: Yup.string().required('This field is requried'),
    desc: Yup.string().required('This field is requried'),
    amount: Yup.string()
      .required('This field is requried')
      .matches(/(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/, 'Not a valid value')
  });

  const formikAdd = useFormik({
    initialValues: {
      code: '',
      desc: '',
      amount: '0.00'
    },
    validationSchema: miscInvSchemaAdd,
    onSubmit: () => {
      const getMiscInvChargesList = miscInvChargesList.find((misc) => misc.code === code);
      if (!getMiscInvChargesList) {
        onAddMiscInvChanges(code, vat, charges, desc, amount);
        setCode('');
        setFieldValueAdd('code', '');
        setVat('V');
        setCharges('');
        setDesc('');
        setFieldValueAdd('desc', '');
        setAmount('0.00');
        setFieldValueAdd('amount', '0.00');

        formikAdd.setTouched({}, false);
        openAlert('success', 'New misc. invoice charge successfully added.');
      } else {
        openAlert('error', 'Misc. invoice charge already added.');
      }

      disableProceed && disableProceed(false);
    }
  });

  const errorsAdd = formikAdd.errors;
  const touchedAdd = formikAdd.touched;
  const handleSubmitAdd = formikAdd.handleSubmit;
  const setFieldValueAdd = formikAdd.setFieldValue;
  const setErrorsAdd = formikAdd.setErrors;

  // Edit
  const miscInvSchemaEdit = Yup.object().shape({
    codeEdit: Yup.string().required('This field is requried'),
    descEdit: Yup.string().required('This field is requried'),
    amountEdit: Yup.string()
      .required('This field is requried')
      .matches(/(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/, 'Not a valid value')
  });

  const formikEdit = useFormik({
    initialValues: {
      codeEdit: '',
      chargesEdit: '',
      descEdit: '',
      amountEdit: '0.00'
    },
    validationSchema: miscInvSchemaEdit,
    onSubmit: () => {
      const getMiscInvChargesListEdit = miscInvChargesList.find((misc) => misc.code === codeEdit);
      if (
        !getMiscInvChargesListEdit ||
        (getMiscInvChargesListEdit && getMiscInvChargesListEdit.code === editCode)
      ) {
        onEditMiscInvChanges(editCode, codeEdit, vatEdit, chargesEdit, descEdit, amountEdit);
        setMode('ADD');
        setCodeEdit('');
        setFieldValueEdit('codeEdit', '');
        setVatEdit('V');
        setChargesEdit('');
        setDescEdit('');
        setFieldValueEdit('descEdit', '');
        setAmountEdit('0.00');
        setFieldValueEdit('amountEdit', '0.00');
        setEditCode('');
        formikEdit.setTouched({}, false);
        openAlert('success', 'Misc. invoice charge successfully updated.');
      } else {
        openAlert('error', 'Misc. invoice charge already exists.');
      }

      disableProceed && disableProceed(false);
    }
  });

  const errorsEdit = formikEdit.errors;
  const touchedEdit = formikEdit.touched;
  const handleSubmitEdit = formikEdit.handleSubmit;
  const setFieldValueEdit = formikEdit.setFieldValue;
  const setErrorsEdit = formikEdit.setErrors;

  const onEditClick = (miscInvCharges) => {
    console.log(miscInvCharges);
    setMode('EDIT');
    setCodeEdit(miscInvCharges.code);
    setFieldValueEdit('codeEdit', miscInvCharges.code);
    setVatEdit(miscInvCharges.vat);
    setChargesEdit(miscInvCharges.charges);
    setDescEdit(miscInvCharges.desc);
    setFieldValueEdit('descEdit', miscInvCharges.desc);
    setAmountEdit(miscInvCharges.amount);
    setFieldValueEdit('amountEdit', miscInvCharges.amount);

    setEditCode(miscInvCharges.code);
    disableProceed && disableProceed(true);
  };

  const onDeleteClick = (miscInvCharges) => {
    onDeleteMiscInvChanges(miscInvCharges.code);
    openAlert('success', `Misc. ${miscInvCharges.code} successfully deleted.`);
  };

  const onUpdateClick = () => {
    handleSubmitEdit();
  };

  const onCancelClick = () => {
    setMode('ADD');
    setCodeEdit('');
    setFieldValueEdit('codeEdit', '');
    setVatEdit('V');
    setChargesEdit('');
    setDescEdit('');
    setFieldValueEdit('descEdit', '');
    setAmountEdit('0.00');
    setFieldValueEdit('amountEdit', '0.00');

    setEditCode('');
    formikEdit.setTouched({}, false);
    disableProceed && disableProceed(false);
  };

  const totalMisc = (misc) => {
    return misc.map(({ amount }) => parseFloat(amount)).reduce((sum, i) => sum + i, 0);
  };

  return (
    <Container>
      <FormikProvider value={formikAdd}>
        <Form autoComplete="off" noValidate>
          <Stack
            direction={{ xs: 'column', sm: 'column', md: 'row' }}
            alignItems="center"
            style={{ alignItems: 'stretch' }}
            mb={1}
          >
            <Stack
              direction={{ xs: 'column', sm: 'column', md: 'row' }}
              style={{ padding: '5px', width: '100%' }}
              spacing={2}
            >
              <TextField
                fullWidth
                id="code"
                name="code"
                label="Code"
                type="text"
                value={code}
                disabled={mode === 'EDIT'}
                inputProps={{ maxLength: 10 }}
                sx={{ maxWidth: { xs: '100%', sm: '100%', md: '200px' } }}
                onChange={(e) => {
                  setCode(e.target.value);
                  setFieldValueAdd('code', e.target.value);
                }}
                error={Boolean(touchedAdd.code && errorsAdd.code)}
                helperText={touchedAdd.code && errorsAdd.code}
              />
              <TextField
                fullWidth
                id="vat"
                name="vat"
                select
                label="VAT"
                defaultValue=""
                value={vat}
                disabled={mode === 'EDIT'}
                onChange={(e) => {
                  setVat(e.target.value);
                }}
                sx={{ maxWidth: { xs: '100%', sm: '100%', md: '200px' } }}
              >
                {vatOption &&
                  vatOption.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
              </TextField>
              <TextField
                fullWidth
                id="charges"
                name="charges"
                label="Charges"
                type="text"
                value={charges}
                disabled={mode === 'EDIT'}
                inputProps={{ maxLength: 100 }}
                onChange={(e) => {
                  setCharges(e.target.value);
                  setFieldValueAdd('charges', e.target.value);
                }}
                error={Boolean(touchedAdd.charges && errorsAdd.charges)}
                helperText={touchedAdd.charges && errorsAdd.charges}
              />
            </Stack>
          </Stack>
          <Stack
            direction={{ xs: 'column', sm: 'column', md: 'row' }}
            alignItems="center"
            style={{ alignItems: 'stretch' }}
            mb={2}
          >
            <Stack
              direction={{ xs: 'column', sm: 'column', md: 'row' }}
              style={{ padding: '5px', width: '100%' }}
              spacing={2}
            >
              <TextField
                fullWidth
                id="desc"
                name="desc"
                label="Explanation"
                type="text"
                value={desc}
                disabled={mode === 'EDIT'}
                inputProps={{ maxLength: 200 }}
                onChange={(e) => {
                  setDesc(e.target.value);
                  setFieldValueAdd('desc', e.target.value);
                }}
                error={Boolean(touchedAdd.desc && errorsAdd.desc)}
                helperText={touchedAdd.desc && errorsAdd.desc}
              />
              <TextField
                fullWidth
                id="amount"
                name="amount"
                label="Amount"
                type="number"
                value={amount}
                disabled={mode === 'EDIT'}
                inputProps={{
                  style: { textAlign: 'right' }
                }}
                sx={{ maxWidth: { xs: '100%', sm: '100%', md: '200px' } }}
                onChange={(e) => {
                  setAmount(e.target.value);
                  setFieldValueAdd('amount', e.target.value);
                }}
                error={Boolean(touchedAdd.amount && errorsAdd.amount)}
                helperText={touchedAdd.amount && errorsAdd.amount}
              />
            </Stack>
            <Stack width={{ xs: '100%', sm: '100%', md: '200px' }} style={{ padding: '5px' }}>
              <Button
                style={{ width: '100%', height: '54px' }}
                variant="contained"
                startIcon={<Icon icon={plusFill} />}
                type="button"
                disabled={mode === 'EDIT'}
                onClick={handleSubmitAdd}
              >
                Add Charge
              </Button>
            </Stack>
          </Stack>
        </Form>
      </FormikProvider>

      <Collapse in={alertOpen}>
        <Alert
          severity={alertType}
          variant="outlined"
          action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={closeAlert}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {alertMessage}
        </Alert>
      </Collapse>

      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <MiscInvChargesListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={miscInvChargesList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredMiscellaneous
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => {
                    const { code, vat, charges, desc, amount } = row;
                    const isItemSelected = selected.indexOf(code) !== -1;

                    return (
                      <TableRow hover key={i} tabIndex={-1}>
                        <TableCell align="left" style={{ verticalAlign: 'top' }}>
                          {mode === 'EDIT' && editCode === row.code ? (
                            <TextField
                              fullWidth
                              id="codeEdit"
                              name="codeEdit"
                              label=""
                              type="text"
                              value={codeEdit}
                              inputProps={{ maxLength: 100 }}
                              sx={{ maxWidth: { xs: '100%', sm: '100%', md: '100px' } }}
                              onChange={(e) => {
                                setCodeEdit(e.target.value);
                                setFieldValueEdit('codeEdit', e.target.value);
                              }}
                              error={Boolean(touchedEdit.codeEdit && errorsEdit.codeEdit)}
                              helperText={touchedEdit.codeEdit && errorsEdit.codeEdit}
                            />
                          ) : (
                            code
                          )}
                        </TableCell>
                        <TableCell align="center" style={{ verticalAlign: 'top' }}>
                          {mode === 'EDIT' && editCode === row.code ? (
                            <TextField
                              fullWidth
                              id="vatEdit"
                              name="vatEdit"
                              select
                              label="VAT"
                              defaultValue=""
                              value={vatEdit}
                              onChange={(e) => {
                                setVatEdit(e.target.value);
                              }}
                              sx={{ maxWidth: { xs: '100%', sm: '100%', md: '100px' } }}
                            >
                              {vatOption &&
                                vatOption.map((option) => (
                                  <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                            </TextField>
                          ) : (
                            vat
                          )}
                        </TableCell>
                        <TableCell align="left" style={{ verticalAlign: 'top' }}>
                          {mode === 'EDIT' && editCode === row.code ? (
                            <TextField
                              fullWidth
                              id="chargesEdit"
                              name="chargesEdit"
                              label=""
                              type="text"
                              value={chargesEdit}
                              inputProps={{ maxLength: 100 }}
                              onChange={(e) => {
                                setCodeEdit(e.target.value);
                              }}
                            />
                          ) : (
                            charges
                          )}
                        </TableCell>
                        <TableCell align="left" style={{ verticalAlign: 'top' }}>
                          {mode === 'EDIT' && editCode === row.code ? (
                            <TextField
                              fullWidth
                              id="descEdit"
                              name="descEdit"
                              label=""
                              type="text"
                              value={descEdit}
                              inputProps={{ maxLength: 100 }}
                              onChange={(e) => {
                                setDescEdit(e.target.value);
                                setFieldValueEdit('descEdit', e.target.value);
                              }}
                              error={Boolean(touchedEdit.descEdit && errorsEdit.descEdit)}
                              helperText={touchedEdit.descEdit && errorsEdit.descEdit}
                            />
                          ) : (
                            desc
                          )}
                        </TableCell>
                        <TableCell align="right" style={{ verticalAlign: 'top' }}>
                          {mode === 'EDIT' && editCode === row.code ? (
                            <TextField
                              fullWidth
                              id="amountEdit"
                              name="amountEdit"
                              label=""
                              type="number"
                              value={amountEdit}
                              inputProps={{ style: { textAlign: 'right' } }}
                              onChange={(e) => {
                                setAmountEdit(e.target.value);
                                setFieldValueEdit('amountEdit', e.target.value);
                              }}
                              sx={{ maxWidth: { xs: '100%', sm: '100%', md: '150px' } }}
                              error={Boolean(touchedEdit.amountEdit && errorsEdit.amountEdit)}
                              helperText={touchedEdit.amountEdit && errorsEdit.amountEdit}
                            />
                          ) : (
                            parseFloat(amount)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                          )}
                        </TableCell>

                        <TableCell align="right">
                          {(mode === 'EDIT' && editCode === row.code) || mode === 'ADD' ? (
                            <MiscInvChargesListMoreMenu
                              item={row}
                              mode={mode}
                              onEditClick={onEditClick}
                              onDeleteClick={onDeleteClick}
                              onUpdateClick={onUpdateClick}
                              onCancelClick={onCancelClick}
                            />
                          ) : (
                            <></>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={3} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={miscInvChargesList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Container>
  );
}
